<template>
  <div style="margin: 0px; background: #fff">
    <div class="headvideo">
      <div class="headvideo-img">
        <img :src="awemeDetail.aweme_cover || defultCover"/>
      </div>
      <div class="headvideo-message">
        <div class="headvideo-message-title">{{ awemeDetail.aweme_title || '--'}}</div>
        <div class="video_message">
          <span class="count_box"
          ><svg-icon
              icon-class="favorites-fill"
              style="color: #e34e3a; margin-right: 8px"
          />{{ this.common.numEdit(awemeDetail.digg_count || 0) }}</span
          >
          <span class="count_box"
          ><svg-icon
              icon-class="comments-fill"
              style="color: #516ef3; margin-right: 8px"
          />{{ this.common.numEdit(awemeDetail.comment_count || 0) }}</span
          >
          <span class="count_box"
          ><svg-icon
              icon-class="rate-fill"
              style="color: #f67c52; margin-right: 8px"
          />{{ this.common.numEdit(awemeDetail.share_count || 0) }}</span
          >
        </div>
        <div class="video_hot">
          <span>视频热词：</span>
          <span
              class="hot_tag"
              v-for="(val, idx) in awemeDetail.aweme_topic"
              :key="idx"
          >
            {{ val }}
          </span>
        </div>
        <a class="play_btn" @click="handlePlay"
        >
          <svg-icon icon-class="play" style="margin-right: 8px"/>
          播放视频</a
        >
        <div class="video_time">
          <span>发布时间: {{ awemeDetail.publish_time }}</span>
          <span
          >数据更新时间:
            {{
              awemeDetail.mtime
            }}</span
          >
        </div>
      </div>
    </div>
    <div style="height: 12px; width: 100%; background: #eff6ff"></div>
    <main>
      <div class="tabs_detail">
        <div class="tabs_detail_pane">
          <a
            :class="activeName == 'videoDetail' ? 'active' : ''"
            @click="handleClickVideo('videoDetail')"
          >
            视频详情
          </a>
        </div>
      </div>
      <div v-show="activeName == 'videoDetail'" style="padding-top: 28px">
        <div class="detail">
          <div class="detail-message">
            <div class="img_box">
              <img
                  :style="author.avatar ? { border: '1px solid #ccc' } : ''"
                  :src="author.avatar || defultImg"
                  class="img_box"
              />
            </div>
            <div class="author_messgae">
              <p class="author_name">{{ author.nickname }}</p>
              <p class="total_fans">
                {{ this.common.numEdit(author.fans_count_total) }}
              </p>
            </div>
          </div>
          <div class="detail-number">
            <div class="effect_box">
              <p>平均点赞</p>
              <span>{{ this.common.numEdit(anchorAvg.avg_digg_count) }}</span>
            </div>
            <a-divider type="vertical" style="height: 60%"/>
            <div class="effect_box">
              <p>平均评论</p>
              <span>{{
                  this.common.numEdit(anchorAvg.avg_comment_count)
                }}</span>
            </div>
            <a-divider type="vertical" style="height: 60%"/>
            <div class="effect_box">
              <p>平均分享</p>
              <span>{{ this.common.numEdit(anchorAvg.avg_share_count) }}</span>
            </div>
          </div>
        </div>
        <div class="divider-title">视频热词Top 10</div>
        <HotwordsBar
          :id="'hotCommentTop'"
          v-show="
            awemeHotwords &&
            awemeHotwords.hotwords &&
            awemeHotwords.hotwords.length > 0
          "
          :chart-data="awemeHotwords"
          :box-style="{ width: '770px', height: '200px' }"
          :chart-style="chartStyle"
        />
        <div
          v-show="
            !awemeHotwords ||
            !awemeHotwords.hotwords ||
            awemeHotwords.hotwords.length == 0
          "
          style="
            font-size: 18px;
            margin: 40px 46px 40px 16px;
            text-align: center;
            color: #ccc;
          "
        >
          暂无数据
        </div>
        <div class="progress-all">
          <div class="progress">
            <div
              class="progress-title"
              :style="{
                color:
                  parseInt(likeOrDislike.good_rate * 100) < 50
                    ? '#4A58F3'
                    : parseInt(likeOrDislike.good_rate * 100) > 50
                    ? '#F67C52'
                    : '#57c52d',
              }"
            >
              {{
                parseInt(likeOrDislike.good_rate * 100) < 50 &&
                likeOrDislike.total !== 0
                    ? "情感偏负向"
                    : parseInt(likeOrDislike.good_rate * 100) > 50
                    ? "情感偏正向"
                    : "情感相等"
              }}
            </div>
            <div class="progress-main">
              <span style="width: 80px">正向情感</span>
              <svg-icon
                  :icon-class="'laugh'"
                  style="color: #f67c52; margin: 0 15px; font-size: 22px"
              />
              <div v-if="likeOrDislike.total" class="progress-main-div">
                <div :style="{ width: likeOrDislike.good_rate * 100 + '%' }"/>
                <span
                    :style="{
                    left:
                      'calc(' +
                      likeOrDislike.good_rate * 100 +
                      '%' +
                      ' - 16px)',
                  }"
                />
              </div>
              <div v-if="!likeOrDislike.total" class="progress-main-div">
                <div :style="{ width: '50%' }"/>
                <span :style="{ left: 'calc(' + 50 + '%' + ' - 16px)' }"/>
              </div>
              <svg-icon
                  :icon-class="'cry'"
                  style="color: #4a58f3; margin: 0 15px; font-size: 22px"
              />
              <span style="width: 80px">负向情感</span>
            </div>
            <div class="tips_rate">
              {{
                likeOrDislike.total
                    ? `${(likeOrDislike.good_rate * 100).toFixed(1)}%`
                    : 0
              }}
            </div>
          </div>
        </div>

        <div class="divider-title">全部评论</div>
        <ul
          class="comment_box"
          v-infinite-scroll="getCommentData"
          infinite-scroll-disabled="disabledLoad"
          infinite-scroll-immediate="false"
          infinite-scroll-distance="40"
          style="liststyle: none"
        >
          <li
              v-if="commentList.length === 0"
              style="
              font-size: 18px;
              margin: 40px 0;
              text-align: center;
              color: #ccc;
            "
          >
            暂无数据
          </li>
          <li
              v-for="(val, idx) in commentList"
              :key="idx"
              class="comment_list"
              v-else
          >
            <div class="list_box">
              <span><a-avatar :src="val.fans_avatar"/></span>
              <div class="content_box">
                <span class="nickname">{{ val.fans_nickname }}</span>
                <div class="comment">{{ val.content }}</div>
                <div class="issue_time">{{ val.publish_time }}</div>
              </div>
            </div>
            <div class="icon_box">
              <svg-icon
                  :icon-class="'favorites-fill'"
                  style="color: #ff1c05; margin: 0 10px; font-size: 16px"
              />
              <div style="margin: 0 10px; color: #8f94a2">
                {{ val.digg_count }}
              </div>
            </div>
          </li>
          <p style="height: 30px; line-height: 30px">
            <span v-if="loading">加载中...</span
            ><span v-if="noMore && commentList.length > 0" style="height: 30px">没有更多了</span>
          </p>
        </ul>
      </div>
    </main>
  </div>
</template>

<script>
import HotwordsBar from "./HotwordsBar";
import PieBar from "./pie";
import awemeApi from '@/api/aweme'

export default {
  components: {
    HotwordsBar,
    PieBar
  },
  data() {
    return {
      awemeId: this.$route.params.awemeId || '',
      awemeDetail: {},
      author: {},
      anchorAvg: {},
      awemeHotwords: {},
      likeOrDislike: {},
      loading: false,
      commentList: [],
      pagination: {
        current: 0,
        page_size: 25
      },
      noMore: false,
      activeName: "videoDetail", // 视频详情，视频观众分析tab名字
      // 热词柱形图样式
      chartStyle: {
        showTitle: false,
        color: ["#31B78D", "#27B388"],
      },
      // 默认头像
      defultImg:
          "https://afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/images/defultImg.jpg",
      // 默认视频图片
      defultCover:
          "https://afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/images/defultCover.jpg",
    };
  },
  methods: {
    async fetchAwemeDetailKS() {
      try {
        const params = {
          awemeId: this.awemeId
        }
        const res = await awemeApi.fetchAwemeDetailKS(params)
        
        this.awemeDetail = res
        this.fetchAwemeAuthorDetailKS()
        this.fetchAwemeAuthorAwemeAvgKS()
      } catch (e) {
        console.error(e)
      }
    },
    async fetchAwemeAuthorDetailKS() {
      try {
        const params = {
          authorId: this.awemeDetail.author_id
        }
        const res = await awemeApi.fetchAwemeAuthorDetailKS(params)

        this.author = res
      } catch (e) {
        console.error(e)
      }
    },
    async fetchAwemeAuthorAwemeAvgKS() {
      try {
        const params = {
          author_id: this.awemeDetail.author_id
        }
        const res = await awemeApi.fetchAwemeAuthorAwemeAvgKS(params)

        this.anchorAvg = res.aweme_avg
      } catch (e) {
        console.error(e)
      }
    },
    async fetchAwemeHotwordsKS() {
      try {
        const params = {
          awemeId: this.awemeId
        }
        const res = await awemeApi.fetchAwemeHotwordsKS(params)
        
        this.awemeHotwords = res
      } catch (e) {
        console.error(e)
      }
    },
    async fetchAwemeSentimentAnalysisKS() {
      try {
        const params = {
          awemeId: this.awemeId
        }
        const res = await awemeApi.fetchAwemeSentimentAnalysisKS(params)

        this.likeOrDislike = res
      } catch (e) {
        console.error(e)
      }
    },
    async fetchAwemeCommentKS() {
      this.loading = true
      try {
        const params = {
          awemeId: this.awemeId,
          ...this.pagination
        }
        const res = await awemeApi.fetchAwemeCommentKS(params)

        this.loading = false
        this.commentList = [...this.commentList, ...res.list]
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    getCommentData() {
      this.pagination.current++
      this.fetchAwemeCommentKS() 
    },
    handlePlay() {
      if (this.awemeDetail.aweme_id) {
        let url
        url = this.awemeDetail.aweme_url
        window.open(url);
      }
    }
  },
  mounted() {
    this.fetchAwemeDetailKS()
    // this.fetchAwemeHotwordsKS()
    this.fetchAwemeSentimentAnalysisKS()
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.headvideo {
  font-size: 14px;
  color: #17233d;
  display: flex;
  padding: 44px 20px 20px 36px;

  &-img {
    height: 133px;
    width: 100px;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }

  &-message {
    &-title {
      padding-bottom: 8px;
    }

    .video_message {
      padding-bottom: 10px;

      .count_box {
        display: inline-block;
        padding-right: 48px;
      }
    }

    .video_hot {
      padding-bottom: 14px;

      .hot_tag {
        padding: 2px 16px 2px 11px;
        margin: 0 6px;
        background: rgba(74, 88, 243, 0.1);
        border-radius: 4px;
        color: #4a58f3;
      }
    }

    .play_btn {
      display: block;
      font-size: 12px;
      color: #fff;
      font-weight: bold;
      width: 107px;
      height: 36px;
      background-image: linear-gradient(180deg, #64a9f3 0%, #516ef3 100%);
      border-radius: 8px;
      text-align: center;
      line-height: 36px;
      margin-bottom: 12px;
    }

    .video_time {
      font-size: 12px;
      color: #8f94a2;

      span {
        padding-right: 18px;
      }
    }
  }
}

main {
  padding: 24px 20px 20px 36px;

  .tabs_detail {
    border-bottom: 1px solid #f1f2f5;
    padding: 0 0 10px 16px;
    display: flex;
    font-size: 16px;

    .tabs_detail_pane {
      padding-right: 68px;

      a {
        color: #8f94a2;
        padding-bottom: 8px;
      }

      .active {
        font-weight: bold;
        color: #4a58f3;
        border-bottom: 4px solid #4a58f3;
      }
    }
  }

  .divider-title {
    margin-top: 30px;
    border-left: 4px solid #4a58f3;
    padding-left: 12px;
    margin-left: 16px;
    font-size: 14px;
    font-weight: bold;
    color: #17233d;
  }

  .detail {
    display: flex;
    justify-content: space-between;
    padding: 10px 51px 32px 16px;

    &-message {
      display: flex;
      align-items: center;

      .img_box {
        height: 62px;
        width: 62px;
        margin-right: 12px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .author_messgae {
        font-size: 16px;
        color: #17233d;

        .author_name {
          font-weight: bold;
          padding-bottom: 5px;
        }

        .total_fans {
          padding-bottom: 5px;
        }
      }
    }

    &-number {
      display: flex;
      align-items: center;

      .effect_box {
        padding: 0 43px;
        text-align: center;

        p {
          font-size: 14px;
          color: #8f94a2;
          padding-bottom: 4px;
        }

        span {
          font-size: 20px;
          font-weight: bold;
          color: #17233d;
        }
      }
    }
  }

  .progress-all {
    padding-left: 21px;

    .progress {
      width: 80%;
      margin: 30px 0;

      .tips_rate {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
      }

      &-title {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 10px;
      }

      &-main {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        span {
          font-size: 14px;
          color: #999;
        }

        &-div {
          width: 450px;
          height: 10px;
          background: #4a58f3;
          position: relative;

          div {
            height: 10px;
            position: absolute;
            top: 0;
            left: 0;
            background: #f67c52;
          }

          span {
            position: absolute;
            top: 20px;
          }
        }
      }
    }
  }

  .comment_box {
    max-height: 500px;
    overflow: auto;
    margin: 20px 46px 20px 16px;

    .comment_list {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 10px 0 15px 0;
      border-bottom: 1px solid #f1f2f5;

      .list_box {
        flex: 12;
        display: flex;
        align-items: center;

        .emotion_icon {
          margin-right: 30px;
        }

        .content_box {
          padding-left: 16px;

          .nickname {
            display: inline-block;
            font-size: 14px;
            color: #8f94a2;
            padding-bottom: 4px;
          }

          .comment {
            font-size: 14px;
            color: #17233d;
            padding-bottom: 4px;
          }

          .issue_time {
            font-size: 12px;
            color: #8f94a2;
          }
        }
      }

      .icon_box {
        flex: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        // align-self: flex-start;
      }
    }
  }
}

// .el-drawer__header {
//   margin-bottom: 0 !important;
//   padding: 10px 20px;
// }

.el-loading-spinner {
  top: 25% !important;
  margin-top: -21px;
  width: 100%;
  text-align: center;
  position: absolute;
}

.el-tabs__content {
  background-color: #fff !important;
  padding-left: 20px;
}

.el-divider--vertical {
  height: 2em;
}

.address_box {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  .title {
    border-left: 4px solid #4a58f3;
    padding-left: 12px;
    font-size: 14px;
    font-weight: bold;
    color: #17233d;
    height: 16px;
  }

  .button_group {
    display: flex;

    :first-child {
      border-radius: 4px 0 0 4px;
    }

    :last-child {
      border-radius: 0 4px 4px 0;
    }

    a:hover {
      background: rgba(74, 88, 243, 0.1);
      color: #4a58f3;
    }

    a {
      height: 26px;
      width: 51px;
      font-size: 12px;
      color: #17233d;
      text-align: center;
      line-height: 26px;
      background: #fff;
    }

    .primary {
      color: #ffffff !important;
      background: #4a58f3 !important;
    }
  }
}

.city_table_rate {
  width: 100%;
  margin: 5% auto;

  .boay_hidden {
    height: 350px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }

  tr {
    display: flex;
    width: 100%;

    th {
      background: #fafafa;
      height: 36px;
      line-height: 36px;
    }

    td {
      border-top: 1px solid #f1f2f5;
    }

    th,
    td {
      flex: 1;
      text-align: center;
      font-size: 12px;
      color: #17233d;
      height: 35px;
      line-height: 35px;
    }
  }
}
</style>
